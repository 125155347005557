<template>
  <section>
    <RecipeFilter @data="postFetch" />
    <Preloader v-if="callingAPI" />
    <RecipeTable
      :recipes="items"
      :tags="tags"
      :totalCount="totalCount"
      :current-page="page"
      :on-page-change="setPageAndGetRecords"
    />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Preloader from '@/components/preloader/Preloader'
import RecipeFilter from '@/components/recipe/RecipeFilter'
import RecipeTable from '@/components/table/RecipeTable'
import CoreApi from '@/api/core'

export default {
  name: 'RecipeListView',
  components: {
    Preloader,
    RecipeFilter,
    RecipeTable
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    items () {
      return this.$store.getters['recipe/list']
    },
    page () {
      return this.$store.getters['recipe/page']
    },
    totalCount () {
      return this.$store.getters['recipe/totalCount']
    }
  },
  data () {
    return {
      tags: {}
    }
  },
  methods: {
    getItems () {
      this.$store.dispatch('recipe/fetch')
        .then(({ data }) => {
          this.dataLoaded = true
          this.postFetch(data)
        })
    },
    async postFetch (data) {
      const tags = []
      const publishedPromises = data.map(async (recipe) => {
        recipe.tags.forEach(tagId => {
          if (this.tags[tagId] === undefined) {
            this.tags[tagId] = true
            tags.push(tagId)
          }
        })
        return this.getRecipeArticles(recipe)
      })
      if (tags.length > 0) {
        const tagList = await this.$store.dispatch('tag/fetchByIds', tags)
        tagList.data.forEach(tag => {
          this.tags[tag.id] = tag
        })
      }
      await Promise.all(publishedPromises)

      const totalCount = this.$store.getters['recipe/totalCount']
      this.$store.commit('recipe/storeList', { data, totalCount })
    },
    getRecipeArticles (recipe) {
      return CoreApi().get('/article?view=minimal&filter_eq[setting.status]=published&filter_eq[recipe]=' + recipe.id)
        .then(response => {
          recipe.publishedCount = response.data.totalCount
          if (recipe.publishedCount > 0) {
            recipe.published = true
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('recipe/setPage', page)
      this.getItems()
    }
  },
  mounted () {
    this.getItems()
    this.$store.dispatch('recipeCategory/fetchAll')
  }
}
</script>
