<template>
  <AbstractDataTable
    :headers="tableHeaders"
    :items="recipes"
    :total-count="totalCount"
    :current-page="currentPage"
    :on-page-change="onPageChange"
    store-name="recipe"
    table-css-class="recipe-table"
    >
    <template #tableBody="{ item }">
      <TitleColumn
        :title-header="tableHeaders[0].name"
        :title-value="item.id"
        subtitle
      />
      <TitleColumn
        :title-header="tableHeaders[1].name"
        :title-value="getSiteName(item.site)"
      />
      <TitleColumn
        :title-header="tableHeaders[2].name"
        :title-value="item.title"
      />
      <TitleColumn
        :title-header="tableHeaders[3].name"
        :title-value="getCategoryName(item.category)"
      />
      <BlockArrayColumn
        :header="tableHeaders[4].name"
        :blocks="getTagsNames(item.tags)"
      />
      <TitleColumn
        :title-header="tableHeaders[5].name"
        :title-value="item.publishedCount"
      />
      <CreatedByAndAtColumn
        :created-at="item.createdAt"
        :created-by="item.createdBy"
      />
      <StatusColumn
        :header="tableHeaders[7].name"
        :isActive="Boolean(item.published)"
        :active-text="$t('article.status_published')"
        :inactive-text="$t('topicOffer.status.new')"
      />
    </template>
  </AbstractDataTable>
</template>

<script>
import AbstractDataTable from '@/components/table/AbstractDataTable'
import TitleColumn from '@/components/table/columns/TitleColumn'
import CreatedByAndAtColumn from '@/components/table/columns/CreatedByAndAtColumn'
import StatusColumn from '@/components/table/columns/StatusColumn'
import BlockArrayColumn from '@/components/table/columns/BlockArrayColumn'

export default {
  name: 'RecipeTable',
  props: {
    recipes: {
      type: Array,
      required: true
    },
    tags: {
      type: Object,
      required: true
    },
    totalCount: {
      type: [Number, String],
      default: 0
    },
    currentPage: {
      type: Number,
      required: true
    },
    onPageChange: {
      type: Function,
      required: true
    }
  },
  components: {
    BlockArrayColumn,
    StatusColumn,
    CreatedByAndAtColumn,
    TitleColumn,
    AbstractDataTable
  },
  data () {
    return {
      tableHeaders: [
        { name: this.$t('system.id') },
        { name: this.$t('modal.site') },
        { name: this.$t('modal.title') },
        { name: this.$t('modal.category') },
        { name: this.$t('modal.tags') },
        { name: this.$t('modal.published_count') },
        {
          name: this.$t('system.created_at'),
          subHeader: this.$t('system.created_by')
        },
        { name: this.$t('modal.status') },
        { name: this.$t('actions') }
      ],
      categories: []
    }
  },
  methods: {
    getSiteName (siteId) {
      return this.$store.getters['site/siteById'](siteId)?.shortTitle
    },
    getCategoryName (categoryId) {
      return this.$store.getters['recipeCategory/get'](categoryId)?.title
    },
    getTagsNames (itemTags) {
      return itemTags.map((tagId) => this.tags[tagId] ? this.tags[tagId].title : tagId)
    }
  }
}
</script>
<style lang="scss">
  .recipe-table {
    &__thead,
    &__tr {
      @include bp(12) {
        grid-template-columns: rem(50px) rem(50px) auto rem(80px) rem(130px) rem(80px) rem(130px) rem(100px) max-content;
      }
      @include bp(14) {
        grid-template-columns: rem(50px) rem(50px) auto rem(80px) rem(130px) rem(80px) rem(130px) rem(100px) rem(138px);
      }
    }
  }
</style>
